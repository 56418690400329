import {
  CellContentType,
  TableCategory,
  TableHeaders
} from '@/shared/components/table/models';
import moment from 'moment-timezone';
import { DATE_API_FORMAT } from '@/utils/date.util';
import { i18n } from '@/i18n';

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}

export const Categories1TableHeaders: TableHeaders[] = [
  // { key: 'number', label: 'NO' },
  {
    key: 'ck',
    label: i18n.tc('common.ck'),
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'id',
    label: i18n.tc('mainTable.booking')
  },
  {
    key: 'shippingLine',
    label: i18n.tc('order.ssl')
  },
  {
    key: 'billTo',
    label: i18n.tc('order.billTo')
  },
  {
    key: 'erd',
    label: i18n.tc('order.erd'),
    formatter: getDay
  },
  {
    key: 'cutOff',
    label: i18n.tc('mainTable.co'),
    formatter: getDay
  }
];

export const Container1Headers: TableHeaders[] = [
  {
    key: 'containerSize1',
    label: i18n.tc('mainTable.dispatchBooking.contSize', 1, { container: 1 })
  },
  {
    key: 'containerType1',
    rendererKey: 'cntr1',
    label: i18n.tc('mainTable.dispatchBooking.contType', 1, { container: 1 }),
    collapsed: false
  },
  {
    key: 'containerTotal1',
    rendererKey: 'cntr1',
    label: i18n.tc('mainTable.dispatchBooking.contTotal', 1, { container: 1 }),
    collapsed: false
  },
  {
    key: 'containerUsed1',
    rendererKey: 'cntr1',
    label: i18n.tc('mainTable.dispatchBooking.contUsed', 1, { container: 1 }),
    collapsed: false
  },
  {
    key: 'containerBalance1',
    rendererKey: 'cntr1',
    label: i18n.tc('mainTable.dispatchBooking.contBalance', 1, {
      container: 1
    }),
    collapsed: false
  }
];

export const Container2Headers: TableHeaders[] = [
  {
    key: 'containerSize2',
    label: i18n.tc('mainTable.dispatchBooking.contSize', 1, { container: 2 })
  },
  {
    key: 'containerType2',
    rendererKey: 'cntr2',
    label: i18n.tc('mainTable.dispatchBooking.contType', 1, { container: 2 }),
    collapsed: false
  },
  {
    key: 'containerTotal2',
    rendererKey: 'cntr2',
    label: i18n.tc('mainTable.dispatchBooking.contTotal', 1, { container: 2 }),
    collapsed: false
  },
  {
    key: 'containerUsed2',
    rendererKey: 'cntr2',
    label: i18n.tc('mainTable.dispatchBooking.contUsed', 1, { container: 2 }),
    collapsed: false
  },
  {
    key: 'containerBalance2',
    rendererKey: 'cntr2',
    label: i18n.tc('mainTable.dispatchBooking.contBalance', 1, {
      container: 2
    }),
    collapsed: false
  }
];

export const Container3Headers: TableHeaders[] = [
  {
    key: 'containerSize3',
    label: i18n.tc('mainTable.dispatchBooking.contSize', 1, { container: 3 })
  },
  {
    key: 'containerType3',
    rendererKey: 'cntr3',
    label: i18n.tc('mainTable.dispatchBooking.contType', 1, { container: 3 }),
    collapsed: false
  },
  {
    key: 'containerTotal3',
    rendererKey: 'cntr3',
    label: i18n.tc('mainTable.dispatchBooking.contTotal', 1, { container: 3 }),
    collapsed: false
  },
  {
    key: 'containerUsed3',
    rendererKey: 'cntr3',
    label: i18n.tc('mainTable.dispatchBooking.contUsed', 1, { container: 3 }),
    collapsed: false
  },
  {
    key: 'containerBalance3',
    rendererKey: 'cntr3',
    label: i18n.tc('mainTable.dispatchBooking.contBalance', 1, {
      container: 3
    }),
    collapsed: false
  }
];

export const BookingCategoriesHeaders: TableCategory[] = [
  {
    key: 'category1',
    label: ' ',
    maxColspan: Categories1TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'cntr1',
    label: i18n.tc('mainTable.dispatchBooking.cntr', 1, {
      container: 1
    }),
    maxColspan: Container1Headers.length,
    minColspan: 1,
    class: 'info-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'cntr2',
    label: i18n.tc('mainTable.dispatchBooking.cntr', 1, {
      container: 2
    }),
    maxColspan: Container2Headers.length,
    minColspan: 1,
    class: 'equipment-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'cntr3',
    label: i18n.tc('mainTable.dispatchBooking.cntr', 1, {
      container: 3
    }),
    maxColspan: Container3Headers.length,
    minColspan: 1,
    class: 'p-out-color',
    collapsed: false,
    opportunityСhange: true
  }
];

export const BookingTableHeaders: TableHeaders[] = [
  ...Categories1TableHeaders,
  ...Container1Headers,
  ...Container2Headers,
  ...Container3Headers
];
